<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card>
      <v-container>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filters.id_band"
          :filters="{ id_band: '2,4' }"
          :isCompany="true"
          @change="handleFilter()"
        />

        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresas"
          item-text="apelido"
          item-value="id_empresa"
          service="sistemaService.empresa"
          v-model="filters.id_empresa"
          :filters="{ id_band: filters.id_band, calcula_comissao: 'S' }"
          :isCompany="true"
          @change="handleFilter()"
        />

        <BaseFilter
          prepend-inner-icon="mdi-finance"
          clearable
          single-line
          label="Indicador"
          item-text="indicador"
          item-value="id_indicador"
          service="sistemaService.indicadores"
          v-model="filters.id_indicador"
          :filters="{ ativo: 'S' }"
          :hasCode="true"
          @change="handleFilter()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>
<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import FilterBottom from "@/components/shared/bottons/FilterBottom";

export default {
  name: "FilterEmpresas",
  components: { BaseFilter, FilterBottom },
  data() {
    return {
      filters: {}
    };
  },
  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
