var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"actualPage":1,"lastPage":_vm.nLastPage},on:{"pageChange":function($event){return _vm.fetchParametrosEmpresas(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Empresas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(723),expression:"723"}],on:{"click":function($event){return _vm.openModal('Cadastrar')}}}),_c('FilterEmpresas',{on:{"selectedFilters":function($event){_vm.fetchParametrosEmpresas((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.bandeiras[item.id_band].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bandeiras[item.id_band].icon)+" ")])]}},{key:"item.id_indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_indicador)+" - "+_vm._s(item.indicador)+" ")]}},{key:"item.comissao",fn:function(ref){
var item = ref.item;
return [(item.comissao)?_c('span',[_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.comissao))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(725),expression:"725"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.openModal('Editar', item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(726),expression:"726"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteParametrosEmpresa(item.id_param_empresa)}}})]}}],null,true)}),_c('ModalCadastro',{attrs:{"empresas":_vm.empresasItems,"indicadores":_vm.indicadoresItems,"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"items":_vm.items},on:{"closeModal":function($event){(_vm.dialog = false), _vm.fetchParametrosEmpresas()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }