<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="toolbar-color">
            <span class="headline ml-2">
              Cadastro de Realizado - Individual
            </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <!-- Segmento -->
                <v-col cols="12" sm="3">
                  <BaseSelect
                    prepend-inner-icon="mdi-domain"
                    clearable
                    single-line
                    label="Segmento"
                    item-text="descricao"
                    item-value="id_band"
                    service="sistemaService.tipoBandeiras"
                    :filters="{ id_band: '2,4' }"
                    v-model="form.id_band"
                    :rules="rules.required"
                    :disabled="locked"
                    :isCompany="true"
                  />
                </v-col>
                <!-- Empresa -->
                <v-col cols="12" sm="3">
                  <BaseSelect
                    prepend-inner-icon="mdi-domain"
                    clearable
                    single-line
                    label="Empresas"
                    item-text="apelido"
                    item-value="id_empresa"
                    service="sistemaService.empresa"
                    :filters="{ id_band: form.id_band, calcula_comissao: 'S' }"
                    :disabled="locked"
                    v-model="form.id_empresa"
                    :isCompany="true"
                  />
                </v-col>
                <!-- Indicadores -->
                <v-col cols="12" sm="3">
                  <BaseSelect
                    prepend-inner-icon="mdi-finance"
                    clearable
                    single-line
                    label="Indicador"
                    item-text="indicador"
                    item-value="id_indicador"
                    service="sistemaService.indicadores"
                    :disabled="locked"
                    :filters="{ ativo: 'S' }"
                    v-model="form.id_indicador"
                    :hasCode="true"
                  />
                </v-col>
                <!-- Valor -->
                <v-col class="mt-2" cols="12" sm="3"
                  ><vuetify-money
                    :rules="rules.required"
                    v-model="form.comissao"
                    label="Comissão"
                    valueWhenIsEmpty="0"
                    :options="optionsVuetifyMoney"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="labelBtn != 'Cadastrar' ? update() : store()"
            >{{ labelBtn }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";

import comparator from "@/mixins/comparatorValue";

export default {
  name: "ModalFuncoesParametrosEmpresas",

  components: {
    BaseSelect
  },

  mixins: [comparator],

  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    items: {
      type: Object
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      },
      locked: false,

      rules: {
        required: [v => !!v || "Obrigatório"]
      },
      form: {}
    };
  },

  methods: {
    async store() {
      if (!this.$refs.form.validate()) return;
      this.form["id_band"] = parseInt(
        this.empresas.find(item => item.id_empresa == this.form.id_empresa)
          .id_band
      );
      await comissao()
        .parametrosEmpresas()
        .store(this.form, { notification: true });
      this.close();
    },

    async update() {
      if (!this.$refs.form.validate()) return;
      this.form["id_band"] = parseInt(
        this.empresas.find(item => item.id_empresa == this.form.id_empresa)
          .id_band
      );
      await comissao()
        .parametrosEmpresas(this.form.id_param_empresa)
        .update(this.form, { notification: true });
      this.close();
    },

    close() {
      this.$emit("closeModal");
      this.$refs.form.reset();
    }
  },

  watch: {
    items(val) {
      if (Object.keys(val).length === 0 && val.constructor === Object) return;
      this.form = Object.assign(this.form, val);
      this.form.comissao = val.comissao?.replace(",", ".") || 0;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  }
};
</script>

<style></style>
