<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :actualPage="1"
      :lastPage="nLastPage"
      @pageChange="fetchParametrosEmpresas(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Empresas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom v-can-access="723" @click="openModal('Cadastrar')" />
          <FilterEmpresas
            @selectedFilters="fetchParametrosEmpresas((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.id_indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>
      <template v-slot:[`item.comissao`]="{ item }">
        <span v-if="item.comissao">
          {{ item.comissao | BrazilianCurrency }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!--<v-btn
          icon
          v-can-access="725"
          @click="openModal('Editar', item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="726"
          icon
          @click="deleteParametrosEmpresa(item.id_param_empresa)"
          color="error"
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom
          :name="'edit'"
          v-can-access="725"
          @click="openModal('Editar', item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="726"
          @click="deleteParametrosEmpresa(item.id_param_empresa)"
        />
      </template>
    </BaseTable>
    <ModalCadastro
      :empresas="empresasItems"
      :indicadores="indicadoresItems"
      :dialog="dialog"
      :labelBtn="labelBtn"
      :items="items"
      @closeModal="(dialog = false), fetchParametrosEmpresas()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import ModalCadastro from "./ModalEmpresas.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterEmpresas from "./FilterEmpresas.vue";
import currencyMixin from "@/mixins/currencyMixin";
import bandeiras from "@/mixins/bandeiras";
import comissao from "@/services/http/comissaoService";

export default {
  components: {
    BaseTable,
    RegisterBottom,
    ModalCadastro,
    FilterEmpresas,
    IconBottom
  },

  mixins: [currencyMixin, bandeiras],

  data() {
    return {
      items: {},
      dialog: false,
      filters: {},
      labelBtn: "",
      loading: false,
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Indicador", value: "id_indicador" },
        { text: "Comissão", value: "comissao", align: "center" },
        { text: "Ações", value: "actions" }
      ],
      info: [],
      nLastPage: 0,
      empresasItems: [],
      indicadoresItems: []
    };
  },

  methods: {
    openModal(label, items = {}) {
      this.dialog = true;
      this.labelBtn = label;
      this.items = items;
    },
    async fetchParametrosEmpresas(filters, nPage = 1) {
      let { data } = await comissao()
        .parametrosEmpresas()
        .show({ page: nPage, ...filters });

      this.info = data.data;
      this.nLastPage = data.last_page;
      this.loading = false;
    },

    async deleteParametrosEmpresa(id) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o parâmetro empresa?",
          "Essa ação não pode ser desfeita"
        );
        await comissao()
          .parametrosEmpresas(id)
          .delete(null, { notification: true });
        await this.fetchParametrosEmpresas();
      } catch (e) {
        console.log(e);
      }
    }
  },

  mounted() {
    this.fetchParametrosEmpresas();
  }
};
</script>

<style></style>
